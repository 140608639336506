@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  color: #444446;
  margin: 0;
}

main {
  min-height: calc(100vh - 333px);
}

h3 {
  font-size: 22px;
  font-weight: normal;
}

a {
  text-decoration: none !important;

  &:visited {
    color: #444446;

  }
}


select {
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
  max-width: 70px;
}

#linkHolder {
  @media only screen and (max-width: 1023px) {
    justify-content: space-between;

  }
}

#hero {
  margin-top: 20px;
  height: calc(80vh - 130px);
  width: 100%;
  background-image: url("../../images/naslovna/hero.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.rhomboid {

  -webkit-transform: skew(-20deg) translateX(-70px);
  -moz-transform: skew(-20deg) translateX(-70px);
  -o-transform: skew(-20deg) translateX(-70px);
  transform: skew(-20deg) translateX(-70px);


  @media only screen and (max-width: 767px) {
    -webkit-transform: skew(-20deg) translateX(-50px);
    -moz-transform: skew(-20deg) translateX(-50px);
    -o-transform: skew(-20deg) translateX(-50px);
    transform: skew(-20deg) translateX(-50px);
  }
}

.inverse-rhomboid {
  -webkit-transform: skew(20deg);
  -moz-transform: skew(20deg);
  -o-transform: skew(20deg);
  transform: skew(20deg);
}

#values p {
  font-weight: lighter !important;
}

.experience-pic {
  background-image: url("../../images/naslovna/tiler-working-renovation-apartment.png");
  background-size: cover;

}

.alice-carousel__prev-btn, .alice-carousel__next-btn {
  width: auto !important;
}

.activity-pic {
  background-image: url("../../images/naslovna/tiler-working-renovation-apartment.png");
  background-size: cover;
  background-position: center center;
}

.image-gallery-image {
  max-height: 500px !important;
}

//Collapsable style

.collapsable {
  padding: 20px;
  color: #0088CE;
  background: white;
  cursor: pointer;
  max-width: 1030px;
  margin: 0 auto 0 auto;
}

.app__content {
  margin: 0 auto;
  max-width: 1030px;

  p {
    margin: 0;
  }
}

.gallery-child {
  margin-bottom: 70px;
  flex: 1 0 21%;

  height: 270px;
  text-align: center;
  min-width: 300px;

  img {
    max-width: 300px;
    object-fit: cover;
  }
}

#about-us-hero {

  background-image: url("../../images/oNama/o-nama.png");
  background-size: cover;
  background-position: center center;
}

#contact-form {
  border: 5px solid grey;
  max-width: 650px;
  padding: 30px;
  text-align: center;
  @media only screen and (min-width: 992px) {
    min-width: 400px !important;
    width: 100%;
  }

  p {
    font-family: 'Poppins', sans-serif;
  }

  label {
    text-align: left !important;
    display: flex;
    flex: 1
  }

  input {
    font-family: 'Poppins', sans-serif;

    height: 35px;
    width: 100%;
    transition: .3s;
    outline: none;

    &:focus {
      border: 2px solid #0088CE
    }
  }

  textarea {
    font-family: 'Poppins', sans-serif;

    width: 100%;
    resize: none;
    outline: none;

    &:focus {
      border: 2px solid #0088CE
    }
  }
}

.reference-container {
  @media only screen and (max-width: 992px) {

    font-size: 10px;

  }
}