code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.gallery-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;

  padding: 10px;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: auto;
  }

}

.gallery-wrapper {
  img {
    transition: .3s;

    &:hover {
      -webkit-box-shadow: 0px 0px 10px -1px rgba(0, 136, 206, 1);
      -moz-box-shadow: 0px 0px 10px -1px rgba(0, 136, 206, 1);
      box-shadow: 0px 0px 10px -1px rgba(0, 136, 206, 1);
    }
  }
}

.wrapper-images {
  text-align: center;

  img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
}

.clicked-text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 17px;
  font-size: 25px;
  background-color: #0088CE;
  padding: 10px 20px;
  width: 480px;
  text-align: center;
}

.gallery-wrapper img {


  cursor: pointer;
}

/* modal */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(27, 27, 27, 0.541);
  display: flex;
  align-items: center;
}

.overlay img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}

.overlay > span {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: #ffffff;
  z-index: 999;
  cursor: pointer;
}

.overlay-arrows_left {
  display: flex;
  background-color: #6d6d6d;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  left: 20px;
  z-index: 999;
}

.overlay-arrows_left svg {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right svg {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right {
  display: flex;
  background-color: #6d6d6d;
  justify-content: space-between;
  position: absolute;
  right: 20px;
  width: 50px;
  height: 50px;
  top: 50%;
  z-index: 999;
}